.options-container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  
  .left-column,
  .right-column {
    flex: 1;
  }
  
  .left-column {
    width: 66%;
  }
  
  .right-column {
    width: 33%;
  }
  
  .bottom-section {
    width: 100%;
    /* Add any additional styling for the bottom section here */
  }
  
  /* Media query for mobile view */
  @media (max-width: 768px) {
    .options-container {
      flex-direction: column;
    }
  
    .left-column,
    .right-column {
      width: 100%;
    }
  }
  