.App {
  text-align: center;
  background-color: #121826;
}

.login-container {
  background-color: #121826;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-container {
  background-color: white;
  padding: 2rem;
  border-radius: 5px;
}

.header {
  width: 100%;
  background-color: #282c34;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: relative;
}

.nav-logo {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-menu {
  z-index: 1000; 
  list-style: none;
  display: flex;
}

.nav-item {
  margin-left: 1rem;
}

.nav-link {
  color: white;
  text-decoration: none;
}

.sign-out {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 4px auto;
  background-color: white;
}

@media (max-width: 480px) {
  .nav-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #282c34;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-item {
    margin: 1rem 0;
  }

  .hamburger {
    display: block;
  }
}

.journal-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

@media (max-width: 480px) {
  .journal-form textarea {
    width: 100%;
    height: 400px;
    resize: none;
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
}

@media (max-width: 480px) {
.container,
.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 4cm);
}}

@media (max-width: 480px) {
.top-section {
  background-image: linear-gradient(to right, #282c34, #4b6cb7);
  color: white;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 800px;
}}

.bottom-section {
  background-image: linear-gradient(to right, #ffffff, #ffccdd);
  color: black;
  padding: 2rem;
  border-radius: 5px;
}

.app-wrapper {
  background-color: #121826;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.options-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 50px;
  min-height: calc(100vh - 4cm);
}

.option-column {
  flex-basis: 30%;
  text-align: center;
  margin-bottom: 20px;
}

.option-column img {
  max-width: 2cm;
  width: 100%;
  height: auto;
}

.submit-button {
  background-color: #ef6a85;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-self: flex-start;
  margin-bottom: 20px; /* Add this line */

}

.submit-button:hover {
  background-color: #45a049;
}

.button-spacing {
  margin-right: 20px;
}

.predefined-queries {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.predefined-query {
  background-color: #f5f5f5;
  border: none;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  width: calc(50% - 5px);
}

.predefined-query:hover {
  background-color: #ebebeb;
}
